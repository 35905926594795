import * as React from "react"
import { Layout } from "../components/layout"
import { heading, paragraph, container } from "./404.module.css"
import { Seo } from "../components/seo"

export default function Contact() {
  return (
    <Layout>
      <div className="w-5/6 md:w-3/5 pt-[20vh] md:pt-[20px] mx-auto">
        <h1 className={heading}>Returns</h1>
        <br />
      <p>No refunds unless a manufacturing error is proven via emailing the customer service email provided on the contact section of the website and the product is returned to the address it was sent from within 2 weeks of purchasing.
</p>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo />
